// eslint-disable-next-line max-lines-per-function
export const useCbModule = () => {
  const inputFields = reactive({
    salutation: {
      name: 'salutation',
      label: 'Kundenanrede',
      type: 'radio',
      placeholder: '',
      value: '',
      options: [
        { label: 'Herr', value: 'Herr' },
        { label: 'Frau', value: 'Frau' }
      ],
      isTextarea: false,
      hasError: false,
      errorMessage: 'Bitte geben Sie eine Anrede an'
    },
    firstname: {
      name: 'firstname',
      label: 'Kundenvorname',
      maxLength: '100',
      type: 'text',
      placeholder: '',
      value: '',
      isTextarea: false,
      hasError: false,
      errorMessage: 'Bitte geben Sie Ihren Vornamen ein'
    },
    lastname: {
      name: 'lastname',
      label: 'Kundennachname',
      maxLength: '100',
      type: 'text',
      placeholder: '',
      value: '',
      isTextarea: false,
      hasError: false,
      errorMessage: 'Bitte geben Sie Ihren Nachnamen ein'
    },
    birthdate: {
      name: 'birthdate',
      label: 'Geburtsdatum',
      type: 'date',
      placeholder: 'xxx@xxx.xx',
      value: '',
      isTextarea: false,
      hasError: false,
      errorMessage: 'Bitte geben Sie Ihr Geburtsdatum an'
    },
    familyNumber: {
      name: 'familyNumber',
      label: 'Familiennummer/Kundennummer',
      maxLength: '50',
      type: 'text',
      placeholder: '',
      value: '',
      isTextarea: false,
      hasError: false,
      errorMessage: 'Bitte geben Sie Ihre Familiennummer/Kundennummer ein'
    },
    insuranceNumber: {
      name: 'insuranceNumber',
      label: 'Versichertennummer',
      maxLength: '10',
      type: 'text',
      placeholder: '',
      value: '',
      isTextarea: false,
      hasError: false,
      errorMessage: 'Bitte geben Sie Ihre Versichertennummer ein'
    },
    email: {
      name: 'email',
      label: 'E-Mail-Adresse für Bearbeitungsstatus',
      maxLength: '80',
      type: 'email',
      placeholder: 'Ihre E-Mail-Adresse',
      value: '',
      isTextarea: false,
      hasError: false,
      errorMessage:
        'Bitte geben Sie eine korrekt formatierte E-Mail-Adresse an, damit wir Sie über den Bearbeitungsstatus Ihres Anfrage informieren können.'
    },
    subject: {
      name: 'subject',
      label: 'Thema',
      type: 'select',
      placeholder: 'Bitte auswählen',
      value: '',
      options: [
        { label: 'curabox Zusammenstellung ändern', value: 'curabox Zusammenstellung ändern' },
        { label: 'curabox Lieferintervall ändern', value: 'curabox Lieferintervall ändern' },
        { label: 'curabox Lieferstatus', value: 'curabox Lieferstatus' },
        { label: 'curabox Lieferanschrift ändern', value: 'curabox Lieferanschrift ändern' },
        { label: 'Kündigung', value: 'Kündigung' },
        { label: 'Beratung', value: 'Beratung' },
        { label: 'Kundendaten ändern', value: 'Kundendaten ändern' },
        { label: 'Sonstiges', value: 'Sonstiges' }
      ],
      isTextarea: false,
      hasError: false,
      errorMessage: 'Bitte geben Sie das Thema Ihrer Nachricht an uns an.'
    },
    description: {
      name: 'description',
      label: 'Beschreibung',
      maxLength: '500',
      type: 'textarea',
      placeholder:
        'Bitte geben Sie uns Ihren konkreten Boxwunsch bzw. Änderungswunsch möglichst detailliert an.',
      value: '',
      isTextarea: true,
      hasError: false,
      errorMessage: 'Bitte beschreiben Sie uns, wie wir Ihnen helfen können.'
    },
    consent: {
      name: 'consent',
      label:
        'Ich stimme zu, dass pflege.de (web care LBJ GmbH) meine personenbezogenen Informationen (auch gesundheitsbezogene), v.a. Kontakt-, Statusinfos und vertragsbezogene Informationen, für die Bearbeitung des Änderungsantrages (im Rahmen der Belieferung mit Pflegehilfsmitteln bzw. der curabox®) sowie für eine etwaige Kontaktaufnahme in diesem Zusammenhang via E-Mail (z.B. Versand Status-E-Mail) verarbeitet.<br>Hinweis: Ein <a href="https://www.pflege.de/kundendatenschutzerklaerung/#geltendmachung" target="_blank">Widerruf</a> ist jederzeit für die Zukunft möglich. Weitere Informationen zum Datenschutz finden Sie <a href="https://www.pflege.de/kundendatenschutzerklaerung/" target="_blank">hier</a>.<br>Sofern ich Einwilligungen und Erklärungen für einen Dritten abgebe, z.B. für eine pflegebedürftige Person, versichere ich, dass mich die Dritte Person zur Abgabe der Einwilligungserklärung bevollmächtigt hat und kann der web care LBJ GmbH diese Vollmacht jederzeit vorlegen.',
      value: '',
      checked: false,
      type: 'checkbox',
      placeholder: 'Beschreibung',
      isTextarea: false,
      hasError: false,
      errorMessage: 'Bitte stimmen Sie der Datenverarbeitung zu'
    }
  })
  const validateEmail = (value) => /[A-Z0-9a-z._%+-]+@[A-Za-z0-9-]+\.[A-Za-z]{2,64}/.test(value)

  // eslint-disable-next-line complexity
  const validateInput = (object, isCallcenterForm = false) => {
    object.hasError = object.value.trim().length === 0
    switch (object.type) {
      case 'email':
        if (isCallcenterForm) {
          object.hasError = object.value.trim().length > 0 ? !validateEmail(object.value) : false
        } else {
          object.hasError = !validateEmail(object.value)
        }
        break
      case 'checkbox':
        object.hasError = object.checked !== true
        break
      default:
        break
    }

    switch (object.name) {
      case 'familyNumber':
      case 'insuranceNumber':
        object.hasError =
          inputFields.insuranceNumber.value.trim().length === 0 &&
          inputFields.familyNumber.value.trim().length === 0
        break
      default:
        break
    }
  }

  const hasAnyErrors = (array) => {
    let err = false

    for (const object of Object.entries(array)) {
      if (object[1].hasError) {
        err = true
        break
      }
    }
    return err
  }
  const validateAllInputs = (isCallCenterForm) => {
    for (const object of Object.entries(inputFields)) {
      validateInput(object[1], isCallCenterForm)
    }
  }

  const getAllData = (array, isCallCenterForm) => {
    const res = {}
    for (const object of Object.entries(array.value)) {
      const value = ref(object[1].value)
      if (object[0] === 'consent') {
        value.value = object[1].label.replace(/<[^>]+>/g, '')
      } else if (object[1].type === 'date') {
        value.value = new Date(object[1].value).toLocaleDateString('de-DE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      }
      res[object[1].name] = value.value
    }
    Object.assign(res, { isCallCenterForm })
    return res
  }

  return {
    inputFields,
    validateInput,
    hasAnyErrors,
    validateAllInputs,
    getAllData
  }
}
