export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"msapplication-TileColor","content":"#005b85"},{"name":"msapplication-TileImage","content":"/favicon-180x180.png"},{"name":"theme-color","content":"#f1f7f7"},{"name":"apple-mobile-web-app-title","content":"pflege.de"},{"name":"mobile-web-app-capable","content":"no"},{"name":"apple-mobile-web-app-capable","content":"no"},{"name":"apple-touch-fullscreen","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"shortcut icon","type":"image/x-icon","href":"https://static-assets.pflege.de/favicon.ico"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"https://static-assets.pflege.de/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"https://static-assets.pflege.de/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"96x96","href":"https://static-assets.pflege.de/favicon-96x96.png"},{"rel":"apple-touch-icon","type":"image/png","sizes":"180x180","href":"https://static-assets.pflege.de/favicon-180x180.png"},{"rel":"icon","type":"image/png","sizes":"512x512","href":"https://static-assets.pflege.de/favicon-512x512.png"},{"rel":"mask-icon","color":"#f1f7f7","href":"https://static-assets.pflege.de/favicon.svg"},{"rel":"icon","type":"svg+xml","href":"https://static-assets.pflege.de/favicon.svg"}],"style":[],"script":[],"noscript":[],"title":"sepac","htmlAttrs":{"lang":"de"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"