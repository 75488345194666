<template>
  <NuxtLayout name="error">
    <main v-if="isProduction">
      <PflegeSectionText :heading="userFriendlyStatusMsg" headingTag="h1" textAlign="center">
        <template v-if="normalizedError.statusCode === 404">
          <p>
            Es tut uns leid, aber die Seite, die Sie suchen, konnte leider nicht gefunden werden.
            Keine Sorge, hier sind einige nützliche Links, die Ihnen helfen könnten:sdf
          </p>
        </template>
        <template v-else>
          <p>
            Es tut uns leid, aber es ist ein Problem aufgetreten. Bitte versuchen Sie es später
            erneut oder versuchen Sie die Startseite aufzurufen:
          </p>
          <p>
            <PflegeButton label="Zur Startseite" variant="secondary" @click="handleError()" />
            : Gehen Sie zurück zur Startseite und beginnen Sie von dort aus.
          </p>
          <p>
            Wir arbeiten daran, die Angelegenheit zu klären und entschuldigen uns für eventuelle
            Unannehmlichkeiten.
            <br />
            <br />
            Vielen Dank für Ihr Verständnis.
          </p>
        </template>
        <template v-if="normalizedError.statusCode === 404" #actions>
          <div class="error-page-footer-content">
            <template v-if="slug[0] === 'pflege'">
              <PflegeButton
                label="Zur Startseite"
                title="Gehen Sie zurück zur Startseite und beginnen Sie von dort aus."
                @click="handleError()"
              />
              <PflegeButton
                label="Zur Kontaktseite"
                title="Falls Sie Unterstützung benötigen, kontaktieren Sie uns gerne."
                @click="handleError('/kontakt')"
              />
            </template>
            <template v-if="slug[0] === 'inko'">
              <PflegeButton
                label="Zur Startseite"
                title="Gehen Sie zurück zur Startseite und beginnen Sie von dort aus."
                @click="handleError('inko', false)"
              />
              <PflegeButton
                label="Zur Kontaktseite"
                title="Falls Sie Unterstützung benötigen, kontaktieren Sie uns gerne."
                @click="handleError('inko/kontakt', false)"
              />
            </template>

            <template v-if="!['pflege', 'inko'].includes(slug[0])">
              <div class="links-wrapper">
                <b>curabox Pflege</b>
                <div class="return-link-holder">
                  <a
                    title="Gehen Sie zurück zur Startseite und beginnen Sie von dort aus."
                    @click="handleError('pflege', false)"
                  >
                    Zur curabox Pflege Startseite
                  </a>
                </div>
                <div class="return-link-holder">
                  <a
                    title="Falls Sie Unterstützung benötigen, kontaktieren Sie uns gerne."
                    @click="handleError('pflege/kontakt', false)"
                  >
                    Zur curabox Pflege Kontaktseite
                  </a>
                </div>
              </div>

              <PflegeDivider class="error-page-divider" borderColor="neutral-30" />

              <div class="links-wrapper">
                <b>curabox Inko</b>
                <div class="return-link-holder">
                  <a
                    title="Gehen Sie zurück zur Startseite und beginnen Sie von dort aus."
                    @click="handleError('inko', false)"
                  >
                    Zur curabox Inko Startseite
                  </a>
                </div>

                <div class="return-link-holder">
                  <a
                    title="Falls Sie Unterstützung benötigen, kontaktieren Sie uns gerne."
                    @click="handleError('inko/kontakt', false)"
                  >
                    Zur curabox Inko Kontaktseite
                  </a>
                </div>
              </div>
            </template>
          </div>
        </template>
      </PflegeSectionText>
      <aside>
        <span>Fehlercode:</span>
        <b>{{ normalizedError.statusCode }}</b>
      </aside>
    </main>
    <main v-else>
      <header>
        <div><b>DEV-MODE</b></div>
        <h1>{{ normalizedError.message }}</h1>
        <span>Status: {{ normalizedError.statusCode }} | {{ normalizedError.statusMessage }}</span>
      </header>
      <div class="pflegescrollbar" v-html="normalizedError.stack" />
      <footer>
        <button @click="handleError()">Fehler löschen und zur Domain-Startseite</button>
      </footer>
    </main>
  </NuxtLayout>
</template>

<script setup>
import * as Sentry from '@sentry/browser'
import { PflegeSectionText, PflegeButton, PflegeDivider } from '@shared'

const props = defineProps({
  error: {
    type: Object,
    default: () => {}
  }
})
const isProduction = process.env.NODE_ENV === 'production'

/*
  clear error and redirect
*/
const { slug } = useRoute().params
const handleError = (route = '', useSlug = true) =>
  clearError({ redirect: `/${useSlug ? slug[0] : ''}${route}` })

/*
  error normalizing, if error is captured in message-field
*/
const event = useRequestEvent()
const normalizedError = { ...props.error }
try {
  const errObjInMsg = JSON.parse(props.error.message)
  normalizedError.statusCode = errObjInMsg.status || normalizedError.statusCode
  normalizedError.message = errObjInMsg.response || normalizedError.message
  normalizedError.statusMessage = errObjInMsg.message || normalizedError.statusMessage
  setResponseStatus(event, normalizedError.statusCode, normalizedError.statusMessage)
} catch (e) {
  Sentry.captureException(e)
}

/*
  generate user friendly title
*/
const userFriendlyStatusMsg =
  normalizedError.statusCode === 404
    ? 'Seite nicht gefunden'
    : 'Fehler - Es ist ein Problem aufgetreten'
useSeoMeta({
  title: userFriendlyStatusMsg
})

/*
  capture errors in sentry
*/
onMounted(() => {
  if (normalizedError.statusCode === 404) {
    Sentry.captureMessage(`404: ${normalizedError.url}`)
  } else {
    Sentry.captureException(normalizedError)
  }
})
</script>

<style scoped>
main {
  position: relative;
  padding: 3rem;
  max-width: var(--maxwidth);
  margin: 4rem auto;
  box-shadow: 0 0.25rem 0.5rem 0 #689f9f20;
  border-radius: 1rem;
  background: rgb(234, 243, 243);
  background: linear-gradient(150deg, rgba(234, 243, 243, 0.7) 0%, rgba(246, 235, 230, 0.5) 100%);
  background-size: 150% 150%;
  animation: bg 15s infinite ease;
}
@media (prefers-reduced-motion) {
  main {
    animation: none;
  }
}
@keyframes bg {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 25% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
pre {
  overflow: auto;
}
.error-page-footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding-top: 1rem;
}
.links-wrapper {
  min-width: 100%;
}
.return-link-holder {
  min-width: 100%;
}
.return-link-holder a {
  display: inline-block;
  cursor: pointer;
}
.error-page-divider {
  width: calc(80% - 4rem);
}
button {
  border: none;
  background: none;
  cursor: pointer;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  transition: background-color var(--time1) ease-in-out;
}
button:is(:hover, :focus) {
  background-color: #fff8;
}
aside {
  position: absolute;
  right: 0.5rem;
  bottom: 0.25rem;
  font-size: 0.5rem;
  opacity: 0.5;
}
</style>
