import { defineNuxtPlugin } from '#app/nuxt'
import { LazyLoadingSpinner, LazyLogo, LazyRichtextResolver, LazyTrustPilot, LazyTuevSeal, LazyWPFooter, LazyWPHeader, LazyAspectRatios, LazyCbChange, LazyCbModule, LazyAlertbox, LazyCard, LazyCardComponent, LazyCardSection, LazyCarouselSection, LazyClosingComponent, LazyCollapseComponent, LazyCollapseSection, LazyDivider, LazyDownloadComponent, LazyDownloadSection, LazyExpansionPanelSection, LazyFooterDefault, LazyHeaderDefault, LazyImageTextSection, LazyIntroComponent, LazyStepSection, LazyTooltip, LazyWPBlockArrow, LazyWPBlockExpansionPanel, LazyWPBlockFAQ, LazyWPBlockGrid, LazyWPBlockHowto, LazyWPBlockPartner, LazyWPBlockPartnernetwork, LazyWPBlockPerks, LazyWPBlockReviews, LazyWPBlockSectionCard, LazyWPBlockSectionExpansionpanel, LazyWPBlockSectionHeroItem, LazyWPBlockSectionText, LazyWPBlockStickyHeader, LazyWPBlockThisispflegede, LazyWPBlockTuev, LazyWPBlockWizco, LazyWPDownloadButton, LazyWPElementButton, LazyWPElementHeading, LazyWPElementImage, LazyWPElementMedia, LazyWPElementParagraph, LazyWPElementRichtext, LazyWPIntro, LazyWPStepList, LazyWizPage } from '#components'
const lazyGlobalComponents = [
  ["LoadingSpinner", LazyLoadingSpinner],
["Logo", LazyLogo],
["RichtextResolver", LazyRichtextResolver],
["TrustPilot", LazyTrustPilot],
["TuevSeal", LazyTuevSeal],
["WPFooter", LazyWPFooter],
["WPHeader", LazyWPHeader],
["AspectRatios", LazyAspectRatios],
["CbChange", LazyCbChange],
["CbModule", LazyCbModule],
["Alertbox", LazyAlertbox],
["Card", LazyCard],
["CardComponent", LazyCardComponent],
["CardSection", LazyCardSection],
["CarouselSection", LazyCarouselSection],
["ClosingComponent", LazyClosingComponent],
["CollapseComponent", LazyCollapseComponent],
["CollapseSection", LazyCollapseSection],
["Divider", LazyDivider],
["DownloadComponent", LazyDownloadComponent],
["DownloadSection", LazyDownloadSection],
["ExpansionPanelSection", LazyExpansionPanelSection],
["FooterDefault", LazyFooterDefault],
["HeaderDefault", LazyHeaderDefault],
["ImageTextSection", LazyImageTextSection],
["IntroComponent", LazyIntroComponent],
["StepSection", LazyStepSection],
["Tooltip", LazyTooltip],
["WPBlockArrow", LazyWPBlockArrow],
["WPBlockExpansionPanel", LazyWPBlockExpansionPanel],
["WPBlockFAQ", LazyWPBlockFAQ],
["WPBlockGrid", LazyWPBlockGrid],
["WPBlockHowto", LazyWPBlockHowto],
["WPBlockPartner", LazyWPBlockPartner],
["WPBlockPartnernetwork", LazyWPBlockPartnernetwork],
["WPBlockPerks", LazyWPBlockPerks],
["WPBlockReviews", LazyWPBlockReviews],
["WPBlockSectionCard", LazyWPBlockSectionCard],
["WPBlockSectionExpansionpanel", LazyWPBlockSectionExpansionpanel],
["WPBlockSectionHeroItem", LazyWPBlockSectionHeroItem],
["WPBlockSectionText", LazyWPBlockSectionText],
["WPBlockStickyHeader", LazyWPBlockStickyHeader],
["WPBlockThisispflegede", LazyWPBlockThisispflegede],
["WPBlockTuev", LazyWPBlockTuev],
["WPBlockWizco", LazyWPBlockWizco],
["WPDownloadButton", LazyWPDownloadButton],
["WPElementButton", LazyWPElementButton],
["WPElementHeading", LazyWPElementHeading],
["WPElementImage", LazyWPElementImage],
["WPElementMedia", LazyWPElementMedia],
["WPElementParagraph", LazyWPElementParagraph],
["WPElementRichtext", LazyWPElementRichtext],
["WPIntro", LazyWPIntro],
["WPStepList", LazyWPStepList],
["WizPage", LazyWizPage],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
